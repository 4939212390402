.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* 
@font-face {
  font-family: 'Foldit';
  font-weight: 600;
  src: local('Foldit'), url(./fonts/Foldit-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'RussoOne';
  font-weight: 600;
  src: local('RussoOne'), url(./fonts/RussoOne-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'FugazOne';
  font-weight: 400;
  src: local('FugazOne'), url(./fonts/FugazOne-Regular.ttf) format('truetype');
} */

@font-face {
  font-family: 'D2Coding';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_three@1.0/D2Coding.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DsDigi';
  src: local('DsDigi'), url(./fonts/DS-DIGI.TTF) format('truetype');
}


@font-face {
  font-family: 'e1234';
  src: local('e1234'), url(./fonts/e1234.regular.ttf) format('truetype');
}


#date_field_input {
  text-align: center;
  /* font-family: 'Noto Serif KR'; */
  font-size: x-large;
  /* color: white; */
}

/* 
#app_title_text {
  text-shadow: 2px 0px 1px lime;
} */

.result_key_numbers {
  font-weight: 700;
  text-decoration-line: underline;
}

/* .dominant-baseline {
  dominant-baseline: central
} */