body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: rgb(28, 28, 28); */
  /* background: rgb(132, 94, 192); */
  background: linear-gradient(0deg, rgba(132, 94, 192, 1) 0%, rgba(44, 115, 210, 1) 36%, rgba(0, 129, 207, 1) 48%, rgba(0, 137, 186, 1) 61%, rgba(0, 142, 155, 1) 80%, rgba(0, 143, 122, 1) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}